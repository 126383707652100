const env = require('./env');
const configs = {
    env,
    isLocalEnv: process.env.NODE_ENV === 'development',
    isLocalOrTesting: !(
        process.env.APP_ENV === 'prod' || process.env.APP_ENV === 'prod-cn'
    ),
    links: {
        referrerOk: ['.moonclub.com'],
    },
    isServer: typeof window === 'undefined',
};

module.exports = configs;
