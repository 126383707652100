import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import {PropsWithChildren} from "react";
import {Typography} from "@mui/material";
import {useAtom} from "jotai";
import {authAtom} from "@/state/user";
import {cookie} from "@/utils/cookie";
import {USER_AUTH_COOKIE} from "@/constants/cookieStorageKeys";
import {useRouter} from "next/navigation";

export interface AccountMenuProps extends PropsWithChildren {
}

export default function AccountMenu(props: AccountMenuProps) {
    const {children} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userInfo, setUserInfo] = useAtom(authAtom);
    const open = Boolean(anchorEl);
    const router = useRouter();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
        handleClick(event)
        setUserInfo(null);
        cookie.set(USER_AUTH_COOKIE, '');
        router.push('/login');
    }

    return (
        <React.Fragment>
            <Box
                component={'div'}
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                }}
            >
                <Avatar alt="photoURL"/>
                <Typography variant="subtitle2" sx={{color: 'text.primary', ml: 1, mr: 1}}>
                    {userInfo?.username}
                </Typography>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    Admin
                </Typography>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem onClick={handleClose}>
                    <Avatar/> Profile
                </MenuItem>
                {/*<MenuItem onClick={handleClose}>*/}
                {/*    <Avatar/> My account*/}
                {/*</MenuItem>*/}
                <Divider/>
                {/*<MenuItem onClick={handleClose}>*/}
                {/*    <ListItemIcon>*/}
                {/*        <Settings fontSize="small"/>*/}
                {/*    </ListItemIcon>*/}
                {/*    Settings*/}
                {/*</MenuItem>*/}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
