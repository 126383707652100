import React, {useEffect, useState} from 'react';
import {useThemeMode} from '@/hooks/useThemeMode';
import {Box, IconButton, Typography, Switch,} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import UserInfoMenu from './UserInfoMenu';
import {
    AppBar,
    AppMain,
    Drawer,
    DrawerHeader,
    LogoBox,
    ProfileBox,
    StyledAccount,
    Toolbar,
} from './styles';

import {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {ChatBubble as ChatBubbleIcon, MoodBad} from '@mui/icons-material';
import {useCookieState} from 'ahooks';
import Menus from '@/components/system/AppMenus';
import {APP_SIDE_BAR_OPEN_STATUS} from '@/constants/cookieStorageKeys';
import {isUndefined} from 'lodash-es';

export interface AppBarProps extends MuiAppBarProps {
    appSidebarOpened?: boolean;
    children?: React.ReactNode;
}

export default function AppDrawerBar(props: AppBarProps) {
    const {appSidebarOpened = true} = props;

    const {theme} = useThemeMode();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const [appSideBarOpenState, setAppSideBarOpenState] = useCookieState(APP_SIDE_BAR_OPEN_STATUS, {
    //     defaultValue: true,
    // });

    const [open, setOpen] = useState<boolean>(
        true
        // typeof appSidebarOpened !== 'undefined' ? appSidebarOpened : true,
    );

    const container = typeof window !== 'undefined' ? window?.document?.body : undefined;

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleMobileDrawerToggle = () => {
        if (!isMobile) return;

        setMobileOpen(!mobileOpen);
    };

    const toggleDrawerOpen = () => {
        setOpen(!open);
        // setAppSideBarOpenState(!appSideBarOpenState);
    };

    useEffect(() => {
        if (isUndefined(appSidebarOpened)) return;
        // setOpen(appSidebarOpened);
    }, [appSidebarOpened]);

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <LogoBox onClick={handleMobileDrawerToggle}>
                        <Typography variant="h6" component="div" color={theme => theme.palette.text.primary}>
                            Moon Club
                        </Typography>
                    </LogoBox>
                    <ProfileBox
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            sx={{
                                ml: '26px',
                                display: {xs: 'none', sm: 'inline-block'},
                            }}
                        >
                            <ChatBubbleIcon/>
                        </IconButton>
                    </ProfileBox>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    padding: props => props.spacing(0, 1),
                    display: {xs: 'none', sm: 'block'},
                }}
            >
                <DrawerHeader
                    sx={{
                        padding: props => props.spacing(0, 1),
                        justifyContent: !open ? 'center' : 'space-between',
                        '@media (min-width:600px)': {
                            height: '68px',
                        },
                    }}
                >
                    <Box sx={{px: 2.5, py: 3, display: 'inline-flex'}}>
                        <MoodBad/>
                    </Box>
                </DrawerHeader>
                <StyledAccount>
                    <UserInfoMenu/>
                </StyledAccount>
                <Menus open={open}/>
            </Drawer>
            <AppMain sx={{flexGrow: 1, maxWidth: '100%', overflowX: 'hidden'}}>
                <DrawerHeader
                    sx={{
                        '@media (min-width:600px)': {
                            height: '68px',
                        },
                    }}
                />
                {/*<Switch/>*/}
                {props.children}
            </AppMain>
        </Box>
    );
}
