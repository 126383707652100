import React, {forwardRef, Ref} from 'react';
import classNames from 'clsx';
import {matchPath} from 'react-router';
import RouteLink, {LinkProps} from 'next/link';
import {endsWith, forEach, is, startsWith} from 'ramda';
import urlParse from 'url-parse';
import configs from '@/configs';
import isInternalDomain from '@/utils/isInternalDomain';
import {useRouter} from 'next/router';
import {Box, SxProps, Theme} from '@mui/material';

export interface LocalizedLinkProps extends Omit<LinkProps, 'href'> {
    lang?: string;
    children?: React.ReactNode;
    className?: string;
    forwardRef?: Ref<any>;
    relOverride?: boolean;
    title?: string;
    target?: React.HTMLAttributeAnchorTarget;
    style?: React.CSSProperties;
    isDisableLanguagePrefix?: boolean;
    forceRefresh?: boolean;
    href?: string;
    rel?: string;
    sx?: SxProps<Theme>;
}

// @ts-ignore
const LocalizedLink = forwardRef<HTMLAnchorElement, LocalizedLinkProps>((props: LocalizedLinkProps, ref) => {
    const {locale = 'en', pathname, asPath} = useRouter();

    const {
        lang = locale,
        children,
        className,
        onClick,
        relOverride,
        title,
        style,
        scroll = true,
        isDisableLanguagePrefix = false,
        prefetch = false,
        forceRefresh,
        shallow = false,
        sx,
    } = props;

    let {href = ''} = props;
    const isActive = href && !!matchPath(href, pathname);

    const usesLanguage = !isDisableLanguagePrefix && startsWith('/', href as string) && lang !== 'en';
    if (usesLanguage) {
        href = `/${lang}${href}`;
    }

    let {target, rel = []} = props;

    if (is(String, rel)) {
        // @ts-ignore
        rel = [rel];
    }

    const parsedUrl = urlParse(href as string, true);
    const hostname = parsedUrl.hostname;

    if (!target) {
        if (hostname && !isInternalDomain(hostname)) {
            let isReferrerOk = false;

            // @ts-ignore
            // forEach(domain => {
            //     if (endsWith(domain as unknown as string, hostname)) {
            //         isReferrerOk = true;
            //         return false;
            //     }
            // }, configs?.links?.referrerOk || []);

            // @ts-ignore
            rel.push('noopener nofollow');
            if (!isReferrerOk) {
                // @ts-ignore
                rel.push('noreferrer');
            }

            target = '_blank';
        }
    }

    const anchorProps = {
        ref, onClick, href, title, style,
    };

    const ALink = (<a  {...anchorProps}>
        <Box className={classNames(className, {active: isActive})} sx={sx}>
            {children}
        </Box>
    </a>);

    const allowDynamicLink = !forceRefresh && href && href[0] === '/';

    if (!href) {
        return children
    }

    // @ts-ignore
    return allowDynamicLink ? (<RouteLink {...anchorProps} scroll={scroll} shallow={shallow} passHref>
        <Box className={classNames(className, {active: isActive})} sx={sx}>
            {children}
        </Box>
    </RouteLink>) : (ALink);
},);

LocalizedLink.displayName = 'LocalizedLink';

export default LocalizedLink;
