import {isNumber, isString} from 'lodash-es';
import {TIME_MAPS} from '@/constants/common';

export const utcFunctions = {
    setHours: (isUTC: boolean) => (isUTC ? 'setUTCHours' : 'setHours'),
    getHours: (isUTC: boolean) => (isUTC ? 'getUTCHours' : 'getHours'),
    setDate: (isUTC: boolean) => (isUTC ? 'setUTCDate' : 'setDate'),
    getDate: (isUTC: boolean) => (isUTC ? 'getUTCDate' : 'getDate'),
    setMonth: (isUTC: boolean) => (isUTC ? 'setUTCMonth' : 'setMonth'),
    getMonth: (isUTC: boolean) => (isUTC ? 'getUTCMonth' : 'getMonth'),
    setFullYear: (isUTC: boolean) => (isUTC ? 'setUTCFullYear' : 'setFullYear'),
    getFullYear: (isUTC: boolean) => (isUTC ? 'getUTCFullYear' : 'getFullYear'),
};

export function addToDate(isAdd = true, count = 0, interval = TIME_MAPS.YEAR, date = new Date(), isUTC = false) {
    if (!date) {
        date = new Date();
    } else if (isString(date) || isNumber(date)) {
        date = new Date(date);
    }

    if (!isAdd) {
        count *= -1;
    }

    if (interval === TIME_MAPS.DAY) {
        date[utcFunctions.setDate(isUTC)](date[utcFunctions.getDate(isUTC)]() + count);
    } else if (interval === TIME_MAPS.MONTH) {
        date[utcFunctions.setMonth(isUTC)](date[utcFunctions.getMonth(isUTC)]() + count);
    } else if (interval === TIME_MAPS.YEAR) {
        date[utcFunctions.setFullYear(isUTC)](date[utcFunctions.getFullYear(isUTC)]() + count);
    } else {
        throw new Error('interval not supported');
    }

    return date;
}
