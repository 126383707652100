import type {AppProps} from 'next/app';
import Head from 'next/head';
import {queryClient} from '@/query/queryClient'
import {NextPage} from 'next';
import {QueryClientProvider} from '@tanstack/react-query';
import SystemLayout from '@/components/system/AppSystemLayout';
import emitter from '@/lib/emitter';
import createEmotionCache from '@/styles/createEmotionCache';
import {CacheProvider, EmotionCache} from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import '@/styles/globals.css';
import 'quilljs-markdown/dist/quilljs-markdown-common-style.css'
import 'quill/dist/quill.snow.css'
import Cookie, {cookie} from '@/utils/cookie';
import {toast, Toaster} from 'react-hot-toast'
import {USER_AUTH_COOKIE} from '@/constants/cookieStorageKeys';
// import { ThemeProvider } from '@mui/material/styles';
import ThemeProvider from '@/theme';
// import { useThemeMode } from '@/hooks/useThemeMode';
import {THEME_MODE_ENU} from '@/constants/global';
import {useRouter} from 'next/router';
import {useAtom} from "jotai";
import {authAtom} from "@/state/user";

const clientSideEmotionCache = createEmotionCache();

export const disabledSystemLayoutRoutePaths = ['/login', '/404'];

export interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
    appSidebarOpened?: boolean;
}

// @ts-ignore
const AppPage: NextPage<MyAppProps> = ({Component, pageProps}: MyAppProps) => {
    const [userInfo, setUserInfo] = useAtom(authAtom);
    const {emotionCache = clientSideEmotionCache, appSidebarOpened = true} = pageProps;
    const {route, pathname, push} = useRouter();

    emitter.on('showGlobalToast', data => {
        toast(data)
    });

    emitter.on('userLogout', () => {
        setUserInfo(null);
        cookie.set(USER_AUTH_COOKIE, '');
        push('/login');
    });

    return (
        <>
            <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width"/>
            </Head>
            <ThemeProvider>
                <QueryClientProvider client={queryClient}>
                    <CacheProvider value={emotionCache}>
                        <CssBaseline enableColorScheme/>
                        {disabledSystemLayoutRoutePaths.includes(pathname) ? (
                            <Component {...pageProps} />
                        ) : (
                            <SystemLayout appSidebarOpened={appSidebarOpened}>
                                <Component {...pageProps} />
                            </SystemLayout>
                        )}
                    </CacheProvider>
                </QueryClientProvider>
            </ThemeProvider>
            <Toaster
                position='top-right'
                gutter={8}
                toastOptions={{
                    style: {
                        background: '#0C130D',
                        color: '#CCE8CD',
                    },
                }}
            />
        </>
    );
};

// The custom App only has getInitialProps at the moment
// @ts-ignore
AppPage.getInitialProps = async appContext => {
    // @ts-ignore
    const {ctx} = appContext;
    const {req, res} = ctx;
    const cookie = new Cookie(req?.headers.cookie);
    const userToken = cookie.get(USER_AUTH_COOKIE);

    // 如果用户未登录且当前页面不是登录页面，则重定向到登录页面
    if (!userToken && ctx.pathname !== '/login') {
        ctx.res.writeHead(302, {Location: '/login'});
        ctx.res.end();
        return;
    } else if (userToken && ctx.pathname === '/login') {
        ctx.res.writeHead(302, {Location: '/dashboard'});
        ctx.res.end();
        return;
    }

    // 获取页面组件的初始属性
    let pageProps = {};
    // @ts-ignore
    if (appContext.Component.getInitialProps) {
        // @ts-ignore
        pageProps = await appContext.Component.getInitialProps(ctx);
    }

    return {pageProps};
};

export default AppPage;
