import {NextPageContext} from 'next';
import Cookies from 'universal-cookie';
import {TIME_MAPS} from '@/constants/common';
import {addToDate} from './time/time';

class Cookie {
    cookie: Cookies;

    constructor(...args: any[]) {
        this.cookie = new Cookies(...args);
    }

    set(name: string, value: string, ttl = 10, period = TIME_MAPS.YEAR, options = {}) {
        console.log(name, value)
        this.cookie.set(name, value, {
            ...options, path: '/', expires: addToDate(true, ttl, period),
        });
    }

    get(name: string) {
        return this.cookie.get(name);
    }

    delete(name: string) {
        this.set(name, '', -1);
    }

    remove(name: string) {
        this.cookie.remove(name, {path: '/'});
    }
}

export default Cookie;

export const cookie = new Cookie({});

let cookies;

export const getCookie: <T>(req: NextPageContext['req'] | undefined, key: string, fallback: T) => T = (req, key, fallback) => {
    if (req) {
        cookies = new Cookie({args: [req.headers.cookie]});
    } else {
        cookies = new Cookie({});
    }
    return cookies.get(key) || fallback;
};
