import React from 'react';
import dynamic from 'next/dynamic';
import AppBar from '@/components/system/AppDrawerBar';
import useMediaQuery from '@mui/material/useMediaQuery';

import { StyledSystemLayout } from './styles';
import { useRecoilValue } from 'recoil';

export interface SystemLayoutProps {
  appSidebarOpened?: boolean;
  enableAppBar?: boolean;
  children: React.ReactNode;
}

export function SystemLayout(props: SystemLayoutProps) {
  const { enableAppBar = true, children, appSidebarOpened } = props;
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (enableAppBar) {
    return (
      <AppBar appSidebarOpened={appSidebarOpened}>
        <StyledSystemLayout>
          <>
            {children}
          </>
        </StyledSystemLayout>
        {/*{(isSmallScreen && <BottomNav />) || ''}*/}
      </AppBar>
    );
  }

  return <StyledSystemLayout>{children}</StyledSystemLayout>;
}

export default SystemLayout;
