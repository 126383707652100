import { ReactNode, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';

import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyle';
import customShadows from './customShadows';
import componentsOverride from './overrides';

export default function ThemeProvider({ children }: { children: ReactNode }) {
  const themeOptions = useMemo(
    () =>
      ({
        palette,
        shape: { borderRadius: 6 },
        typography,
        shadows: shadows(),
        customShadows: customShadows(),
      } as unknown as ThemeOptions),
    [],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        {/*<CssBaseline />*/}
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
