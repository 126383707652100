/**
 * Local environment configuration
 * @type {{internalDomains: {localhost: number}, useSmartlingContextCapture: boolean, hostname: string, endpoints: {assets: string, baseAPI: string, websocket: string, domain: string, imgThumbnailUrl: string, imgUploadUrl: string}, name: string, fingerPointDeviceHost: string, polaris: {appId: string, host: string, saasId: string, token: string}, isLocal: boolean}}
 */
const env = {
  name: 'local',
  isLocal: true,
  hostname: '',
  internalDomains: {
    localhost: 1,
  },
  endpoints: {
    domain: 'beta.moonclub.com',
    assets: '',
    baseAPI: 'https://api.admin-beta.moonclub.com',
    websocket: '',
    moonCrashWebsocket: 'ws://localhost:12000',
    imgUploadUrl: '',
    imgThumbnailUrl: '',
  },
  // GOOGLE_TAG_MANAGER_ID: 'GTM-W7JRSXH',
  fingerPointDeviceHost: 'https://api.commonservice.io',
  polaris: {
    host: 'https://www.devfdg.net',
    appId: '',
    saasId: '',
    token: '',
  },
  // Uncomment for use with `smartling-context-capture` package
  useSmartlingContextCapture: true,
  tg: {
    botName: 'Telegram Casion Beta Bot',
    botLink: 'https://t.me/moonclub_tg_beta_bot'
  },
  sentry: {
    SENTRY_PROJECT: 'back-office-beta',
    SENTRY_DSN: 'https://ec26b3ccb70b270de53a56f80d13de47@o4505874005098496.ingest.us.sentry.io/4507445702098944',
    SENTRY_AUTH_TOKEN:'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'
}
};

module.exports = env;
