import { alpha, styled } from '@mui/material/styles';
import { AppBar as MuiAppBar, Drawer as MuiDrawer, Toolbar as MuiToolbar } from '@mui/material';
import { APP_SIDE_BAR_DRAWER_WIDTH } from '@/constants/global';
import { closedMixin, openedMixin } from './mixins';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const LogoBox = styled('div')(({ theme }) => ({}));

export const ProfileBox = styled('div')(({ theme }) => ({}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open ',
})<MuiAppBarProps & { open: boolean }>(({ theme, open }) => ({
  background: theme.palette.background.default,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: theme.shadows[1],
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - calc(${theme.spacing(8)} + 1px))`,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  ...(open && {
    marginLeft: APP_SIDE_BAR_DRAWER_WIDTH,
    width: `calc(100% - ${APP_SIDE_BAR_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: APP_SIDE_BAR_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      padding: 0,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      padding: 0,
    },
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const AppMain = styled('main')(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(1, 1, 2, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 9, 2),
  },
}));

export const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  margin: theme.spacing(0, 1, 1, 1),
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
