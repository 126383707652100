const env = {
    name: 'prod',
    isLocal: true,
    hostname: '',
    internalDomains: {
        localhost: 1,
    },
    endpoints: {
        domain: 'bo.moonclub.com',
        assets: '',
        baseAPI: 'https://api.admin.moonclub.com',
        websocket: '',
        imgUploadUrl: '',
        imgThumbnailUrl: '',
    },
    // GOOGLE_TAG_MANAGER_ID: 'GTM-W7JRSXH',
    fingerPointDeviceHost: 'https://api.commonservice.io',
    polaris: {
        host: 'https://www.devfdg.net',
        appId: '',
        saasId: '',
        token: '',
    },
    // Uncomment for use with `smartling-context-capture` package
    useSmartlingContextCapture: true,
    tg: {
        botName: 'Telegram Casion Prod Bot',
        botLink: 'https://t.me/MoonClubComBot'
    },
    sentry: {
        SENTRY_PROJECT: 'back-office-production',
        SENTRY_DSN: 'https://bff51341959c81d2ee59a23759c29993@o4505874005098496.ingest.us.sentry.io/4507429265211392',
        SENTRY_AUTH_TOKEN:'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'
    }
};

module.exports = env;
