const envConfigPaths = {
    local: require('./local'),
    beta: require('./beta'),
    production: require('./prod'),
};

console.log(process.env.NEXT_PUBLIC_MOON_ENV, 'process.env.NEXT_PUBLIC_MOON_ENV')
const envConfig = envConfigPaths[process.env.NEXT_PUBLIC_MOON_ENV || 'production'];

module.exports = envConfig;
