import {
  DashboardRounded as Dashboard,
  Wallet,
  ManageAccountsOutlined,
  ShieldMoon,
  WbSunny,
  PanToolAlt,
  Email,
  AdsClickOutlined,
  Telegram,
  AddCard,
} from '@mui/icons-material';
import {
  Group,
  Analytics,
  ApprovalOutlined,
  TopicOutlined,
  TimelapseOutlined,
} from '@mui/icons-material';
import React from 'react';
import { AppMenuGroup, AppMenuItem } from '@/components/system/AppMenus/index';

export const menuConfigs: Array<AppMenuGroup | AppMenuItem> = [
  {
    title: 'Wallet',
    icon: <Wallet />,
    href: '/wallet',
  },
  {
    title: 'Dashboard',
    icon: <Dashboard />,
    href: '/',
  },
  {
    title: 'Players',
    href: '/player',
    icon: <Group />,
  },
  {
    groupTitle: 'Statistics',
    icon: <Analytics />,
    menus: [
      {
        title: 'Withdrawal Statistics',
        href: '/statistics/withdrawal',
        icon: <ApprovalOutlined />,
      },
      {
        title: 'Past N Day Statistics',
        href: '/statistics/past-n-day-statistics',
        icon: <ShieldMoon />,
      },
      {
        title: 'Top Winners & Losers',
        href: '/statistics/top-winners-losers',
        icon: <TopicOutlined />,
      },
      {
        title: 'Latest Deposit',
        href: '/statistics/latest-deposit',
        icon: <TimelapseOutlined />,
      },
    ],
  },
  {
    groupTitle: 'Simulation',
    icon: <WbSunny />,
    menus: [
      {
        title: 'Rush',
        href: '/simulation/rush',
        icon: <ShieldMoon />,
      },
    ],
  },
  {
    groupTitle: 'Tools',
    icon: <PanToolAlt />,
    menus: [
      {
        title: 'Address Detection',
        href: '/tools/address-detection',
        icon: <ShieldMoon />,
      },
      {
        title: 'URL Building and Campaign/Event Tracker',
        href: '/tools/url-building',
        icon: <ShieldMoon />,
      },
    ],
  },
  {
    groupTitle: 'Promotion',
    icon: <AdsClickOutlined />,
    menus: [
      {
        title: 'Telegram Bot Message',
        href: '/promotion/telegram-bot-message',
        icon: <Telegram />,
      },
      {
        title: 'Promotion Card',
        href: '/promotion/cards/list',
        icon: <AddCard />,
      },
    ],
  },
  {
    title: 'Email Templates',
    icon: <Email />,
    href: '/email-templates',
  },
];

export default menuConfigs;
