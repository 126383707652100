import React, {useState} from 'react';
import {Collapse, Divider, List, ListItemText, Tooltip} from '@mui/material';
import menuConfigs from "@/components/system/AppMenus/menuConfigs";
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import TruncatedTextTooltip from '@/components/ui/TruncatedTextTooltip';
import {isString} from 'lodash-es';
import {CListItem, CListItemIcon, MenuGroupItem} from './styles';
import LocalizedLink from '@/components/ui/LocalizedLink';
import {styled} from '@mui/material/styles';

export interface MenusProps {
    menus?: Array<AppMenuItem | AppMenuGroup>;
    open: boolean;
}

export interface AppMenuItem {
    title?: React.ReactNode;
    icon?: React.ReactNode;
    href?: string;
    disabled?: boolean;
}

export interface AppMenuGroup {
    groupTitle: string;
    icon?: React.ReactNode;
    href?: string;
    menus: Array<AppMenuItem | AppMenuGroup | 'divider'>;
}

const NavItem = styled(LocalizedLink)(({theme}) => ({
    '& .MuiButtonBase-root': {
        borderRadius: theme.shape.borderRadius,
    },
    '&.active': {
        '.MuiButtonBase-root': {
            background: theme.palette.action.selected,
            fontWeight: 'fontWeightBold',
        },
    },
}));

const renderAppMenuItem = (item: AppMenuItem, open: boolean) => {
    if (isString(item.title)) {
        return (
            <NavItem href={item.href}>
                <Tooltip
                    title={item.title}
                    disableHoverListener={open}
                    placement={'top'}
                    arrow
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -14],
                                },
                            },
                        ],
                    }}
                >
                    <CListItem disabled={item.disabled} open={open}>
                        {item.icon && <CListItemIcon open={open}>{item.icon}</CListItemIcon>}
                        {(open && (
                                <ListItemText
                                    primary={
                                        <TruncatedTextTooltip title={open ? item.title : ''} arrow placement={'top'}/>
                                    }
                                    primaryTypographyProps={{noWrap: true}}
                                />
                            )) ||
                            ''}
                    </CListItem>
                </Tooltip>
            </NavItem>
        );
    }

    return (
        <NavItem href={item.href}>
            <CListItem disabled={item.disabled} open={open}>
                {item.title}
            </CListItem>
        </NavItem>
    );
};

const AppMenuGroupComponent = ({group, open = true}: { group: AppMenuGroup; open: boolean }) => {
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <NavItem href={group.href}>
            <Tooltip
                title={group.groupTitle}
                disableHoverListener={open}
                placement={'top'}
                arrow
                PopperProps={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -14],
                            },
                        },
                    ],
                }}
            >
                <MenuGroupItem onClick={handleExpandClick} open={open}>
                    {group.icon && <CListItemIcon open={open}>{group.icon}</CListItemIcon>}
                    {(open && (
                            <ListItemText
                                primary={
                                    <TruncatedTextTooltip
                                        title={open ? group.groupTitle : ''}
                                        arrow
                                        placement={'top'}
                                    />
                                }
                                primaryTypographyProps={{noWrap: true}}
                            />
                        )) ||
                        ''}
                    {(open && (expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>)) || ''}
                </MenuGroupItem>
            </Tooltip>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        paddingLeft: props => props.spacing(2),
                    }}
                >
                    {group.menus.map((menu, index) => {
                        if (menu === 'divider') {
                            return <Divider key={index}/>;
                        }

                        if ('groupTitle' in menu) {
                            return <AppMenuGroupComponent key={index} group={menu} open={open}/>;
                        }

                        return <React.Fragment key={index}>{renderAppMenuItem(menu, open)}</React.Fragment>;
                    })}
                </List>
            </Collapse>
        </NavItem>
    );
};

export const Sidebar = ({open}: { open: boolean }) => {
    return (
        <>
            <List
                sx={{
                    paddingRight: props => props.spacing(1),
                    paddingLeft: props => props.spacing(1),
                }}
            >
                {menuConfigs.map((item, index) => {
                    if ('menus' in item) {
                        return <AppMenuGroupComponent key={index} group={item} open={open}/>;
                    }

                    return <React.Fragment key={index}>{renderAppMenuItem(item, open)}</React.Fragment>;
                })}
            </List>
        </>
    );
};

export default Sidebar;
