// import {atomWithImmer} from "jotai-immer";
import {atomWithStorage} from 'jotai/utils'
import {USER_AUTH_COOKIE} from "@/constants/cookieStorageKeys";

export type AuthState = {
    username: string;
    email: string;
    id: string;
} | null

// https://github.com/pmndrs/jotai/issues/32#issuecomment-691170164
export const authAtom = atomWithStorage<AuthState>(USER_AUTH_COOKIE, null);

