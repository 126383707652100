import { styled, alpha } from '@mui/material/styles';

import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';

export const MenuGroupItem = styled(ListItem)<{ open?: boolean }>(({ theme, open }) => ({
  padding: theme.spacing(1, 2),
  '&:hover': {
    cursor: 'pointer',
    // background: theme.palette.grey['900'],
  },
  '&:hover > .MuiListItemText-root': {
    display: 'inline-block',
    transition: 'transform 0.3s ease-in-out',
    transform: 'translateX(0.25em)',
  },
  justifyContent: open ? 'flex-start' : 'center',
}));

export const CListItem = styled(ListItemButton)<{ open?: boolean }>(({ theme, open }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  justifyContent: open ? 'flex-start' : 'center',
}));

export const CListItemIcon = styled(ListItemIcon)<{ open?: boolean }>(({ theme, open }) => ({
  width: 22,
  height: 22,
  '& svg': { fontSize: '22px' },
  minWidth: '14px',
  marginRight: open ? theme.spacing(2) : 0,
}));
