import { grey } from '@mui/material/colors';

export const getCustomThemePalette = (
  mode: 'light' | 'dark'
): CustomThemeOptions => ({
  palette: {
    primary: {
      main: '#FDCB35',
      dark: '#384CFF',
      light: '#6673FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#2C2C35',
      dark: '#888894',
      light: '#8A8A98',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F44336',
      dark: '#D32F2F',
      light: '#E57373',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFA726',
      dark: '#F57C00',
      light: '#FFB74D',
      contrastText: '#000000',
    },
    info: {
      main: '#29B6F6',
      dark: '#0288D1',
      light: '#4FC3F7',
      contrastText: '000000',
    },
    success: {
      main: '#66BB6A',
      dark: '#388E3C',
      light: '#81C784',
      contrastText: '#000000',
    },
    grey: {
      '50': '#FFFFFF',
      '100': '#F5F5FF',
      '200': '#D0D0DA',
      '300': '#A9A9B7',
      '400': '#8A8A98',
      '500': '#70707C',
      '600': '#5B5B65',
      '700': '#40404A',
      '800': '#2C2C35',
      '900': '#eeeeee',
      '950': '#16161B',
      A100: grey.A100,
      A200: grey.A200,
      A400: grey.A400,
      A700: grey.A700,
      '1000': '#0F0F13',
      '1100': '#000000',
    },
    green:{
      '500':'#4CAF50'
    }
  },
});

export default getCustomThemePalette;
