import {useEffect, useMemo, useState} from 'react';
import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import getCustomThemePalette from '../styles/getCustomThemePalette';
import {THEME_MODE_ENU} from '@/constants/global';

// export const ColorModeContext = createContext({
//   toggleColorMode: () => {},
// });

declare module '@mui/material/styles/createMixins' {
    // Allow for custom mixins to be added
    interface Mixins {
        onHover?: CSSProperties;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        standout: true;
    }
}

export const useThemeMode = (themeModeProp = THEME_MODE_ENU.Light) => {
    //  enable dark mode automatically by checking for the user's preference in their OS or browser settings
    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // const colorMode = useContext(ColorModeContext);
    const [themeMode, setThemeMode] = useState<THEME_MODE_ENU>(themeModeProp || THEME_MODE_ENU.Light
        // prefersDarkMode ? 'dark' : 'light'
    );

    const colorMode = useMemo(() => ({
        toggleColorMode: () => {
            setThemeMode((prevMode) => prevMode === THEME_MODE_ENU.Dark ? THEME_MODE_ENU.Light : THEME_MODE_ENU.Dark);
        },
    }), []);

    const isDarkMode = themeMode === THEME_MODE_ENU.Dark;

    const theme = useMemo(() => responsiveFontSizes(createTheme({
        palette: {
            mode: themeMode, ...getCustomThemePalette(themeMode).palette,
        }, mixins: {
            onHover: {
                background: isDarkMode ? 'rgba(53, 6, 239, 0.16)' : 'rgba(53, 6, 239, 0.16)',
            },
        }, shape: {
            borderRadius: 8,
        }, typography: {
            fontFamily: ['Readex', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"',].join(','),
            subtitle1: {
                // color: '#70707C',
            },
            body2: {
                fontSize: 14,
            },
        }, components: {
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
            }, MuiButtonGroup: {
                defaultProps: {
                    disableRipple: true,
                },
            }, MuiTableCell: {
                styleOverrides: {
                    root: {
                        // paddingInlineStart: 0,
                        // paddingInlineEnd: 0,
                    },
                },
            }, MuiPagination: {
                styleOverrides: {
                    ul: {
                        justifyContent: 'center',
                    },
                },
            }, MuiButton: {
                defaultProps: {
                    disableFocusRipple: true, disableRipple: true,
                }, styleOverrides: {
                    root: {
                        background: '#FDCB35', color: '#0F0F13', textTransform: 'none', ':hover': {
                            background: '#F0DA66',
                        },
                    },
                }, variants: [{
                    props: {variant: 'standout'}, style: {
                        background: '#FDCB35', color: '#0F0F13', paddingTop: 8, paddingBottom: 8, ['&:hover']: {
                            background: '#FDCB35',
                        }, [':disabled']: {
                            color: '#0F0F13',
                        },
                    },
                }, // {
                    //   props: { variant: 'text' },
                    //   style: {
                    //     color: '#FFFFFF',
                    //   },
                    // },
                ],
            }, MuiSelect: {
                defaultProps: {}, styleOverrides: {},
            }, MuiTab: {
                defaultProps: {
                    disableFocusRipple: true, disableRipple: true,
                }, styleOverrides: {},
            },
        },
    })), [isDarkMode, themeMode]);

    useEffect(() => {
        setThemeMode(themeModeProp);
    }, [themeModeProp]);
    // useEffect(() => {
    //   setThemeMode(prefersDarkMode ? 'dark' : 'light');
    // }, [prefersDarkMode]);

    return {theme, colorMode};
};
